<template>
   <div class="notice_popup pf_rate">
        <div class="close">
            <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
        </div>
        <project-recr-rate type="popup" :recrStartYyyymmdd="param.recrStartYyyymmdd" :recrEndYyyymmdd="param.recrEndYyyymmdd"/>
    </div>
</template>

<script>
import projectRecrRate from '@/components/highpro/ProjectRecrRate.vue';
export default {
    components: { projectRecrRate },
    props: ['param'],
    data() { return {}; }, 
    methods: {},
};
</script>