<template>

    <div :class="type == 'MyPrj' ? 'prj_rate' : ''">
        <div class="tit">프로젝트 참여율</div>
        <div class="rate">{{ per }}%</div>
        <div :class="type == 'MyPrj' ? 'rate_img' : 'rate'"><img :src="'/images/tec/prj/' + per + '_percent.png'" alt="수행율이미지"/></div>
        
		<div class="ptcp_period" v-if="type == 'MyPrj'">
			<div class="tit">참여기간</div><span class="date">{{recrStartYyyymmdd | date('yyyy.MM.dd')}} - {{recrEndYyyymmdd | date('yyyy.MM.dd')}}</span>
			<div class="tit">참여소속</div><span class="date">{{ projectJoinAsgNm }}</span>
		</div>		
		<template v-else>
			<div class="term">참여기간</div><span class="date">{{recrStartYyyymmdd | date('yyyy.MM.dd')}} - {{recrEndYyyymmdd | date('yyyy.MM.dd')}}</span>
		</template>		
    </div>  
</template>

<script>

/**
 * 프로젝트 참여율
 * 
 * props :
 *          type : 구분
 *          recrStartYyyymmdd	 : 참여시작일 (YYYYMMDD)
 *          recrEndYyyymmdd		 : 참여종료일 (YYYYMMDD)
 */
export default {

    props: {
        type				: String,// 구분
		recrStartYyyymmdd	: String,// 참여시작일
        recrEndYyyymmdd		: String,// 참여종료일
		projectJoinAsgNm    : String, // 프로젝트 참여 소속사
    },

	data() {
		return {};
	},
	methods: {		
	},
	computed: {
		per(){
			var nowYmd = new Date().format('yyyyMMdd');

			if(this.recrStartYyyymmdd == this.recrEndYyyymmdd){
				if(nowYmd <= this.recrStartYyyymmdd) return 0;
				else return 100;
			}

			if(nowYmd < this.recrStartYyyymmdd) return 0;
			else if(nowYmd > this.recrEndYyyymmdd) return 100;

			var now   = this.$format.date(nowYmd, 'date');
			var start = this.$format.date(this.recrStartYyyymmdd, 'date');
			var end   = this.$format.date(this.recrEndYyyymmdd, 'date');
			var per   = (now - start) / (end - start)  * 10;

			if(per >=  9) return  90;
			else if(per <=  1) return  10;
			else return Math.floor(per) * 10;
		}
	}
};
</script>
